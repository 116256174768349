import axios from "axios";
import router from "@/router";

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;

var headers = {
	"Content-Type": "application/json",
	access_token: "",
};

var url = process.env.VUE_APP_BACKEND_URL;

// Interceptors

axios.interceptors.response.use(
	(response) => {
		switch (response.status) {
			case 401:
				router.push({ name: "login-page" });
				break;
		}
		return response;
	},
	(error) => {
		if (error.response) {
			switch (error.response.status) {
				case 401:
					router.push({ name: "login-page" });
					break;
				case 403:
					console.error(error.response.data);
					error.response.data.message =
						error.response.data.message != null &&
						error.response.data.message != ""
							? error.response.data.message
							: "Missing Permissions";
					break;
				case 500:
					console.error(error.response.data);
					error.response.data.message = "An unexpected error occured";
					break;
			}
			return Promise.reject(error.response.data);
		} else {
			switch(error.code){
				case "ERR_NETWORK":
					error.message = "Unable to connect to the network. Are you still connected to the internet? Please check your connection and try again."
			}
			return Promise.reject(error);
		}
	}
);

// Services

var httpService = {
	post(api, data) {
		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
		return axios.post(url + api, data, headers);
	},
	patch(api, data) {
		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
		return axios.patch(url + api, data, headers);
	},
	get(api) {
		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
		if (api.substr(0, "http".length) == "http") {
			return axios.get(api, headers);
		}
		return axios.get(url + api, headers);
	},
	download(api) {
		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
		headers['responseType'] = 'blob';

		if (api.substr(0, "http".length) != "http") {
			api = url + api
		}

		return axios.get(api, headers)
			.then(response => {

				let blob = new Blob([response.data], { type: 'application/pdf' }),
					url = window.URL.createObjectURL(blob)

				window.open(url)
			});
	},
	delete(api) {
		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
		return axios.delete(url + api, headers);
	},
	deleteToken() {
		delete axios.defaults.headers.common["Authorization"];
		delete axios.defaults.headers.common["Accept-Language"];
	},
};
export default httpService;